import PropTypes from 'prop-types';
import React from 'react';

const Page = ({ children, title }) => (
  <article>
    <h1>{title}</h1>
    {children}
  </article>
);

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

Page.defaultProps = {
  children: null,
};

export default Page;
