import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import HtmlContent from '../components/HtmlContent';
import Layout from '../components/Layout';
import Page from '../components/Page';

const PageTemplate = ({ data }) => {
  const { body, fields, frontmatter } = data.page;
  const { title, description } = frontmatter;
  const { slug } = fields;

  return (
    <Layout title={title} description={description} slug={slug}>
      <Page title={title}>
        <HtmlContent>{body}</HtmlContent>
      </Page>
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default PageTemplate;

export const pageTemplateQuery = graphql`
  query PageTemplate($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
      }
      body
    }
  }
`;
